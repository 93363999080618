export const seo = {
  url: 'transport/morski',
  title: {
    pl: 'Transport Morski - Spedycja Morska',
    en: 'Sea Transport',
    ua: 'Морський транспорт',
  },
  desc: {
    pl: `Międzynarodowy transport morski ftl i ltl obsługiwany przez doświadczoną Polską spedycję morską. Fracht i logistyka realizowana przez Omida Sea And Air. Wyceń transport.`,
    en: `We offer FCL, LCL, break-bulk type and oversized sea transport. Thanks to an extensive agency network, we organize loading and delivery of goods from any city and port in the world.`,
    ua: `Ми пропонуємо контейнерні, збірні, насипні та негабаритні морські перевезення. Завдяки розгалуженій агентській мережі ми організовуємо завантаження та доставку вантажів з будь-якого міста та порту світу.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'spedycja',
    'transport morski',
  ],
}

export const intro = {
  title: {
    pl: 'Transport Morski',
    en: 'Sea Transport',
    ua: 'Морський транспорт',
  },
  desc: {
    pl: `Oferujemy morski transport kontenerowy, drobnicowy, typu break-bulk i ponadgabarytowy. Dzięki rozbudowanej sieci agencyjnej organizujemy załadunki i dostawy towarów z dowolnego miasta i portu na świecie.`,
    en: `We offer FCL, LCL, break-bulk type and oversized sea transport. Thanks to an extensive agency network, we organize loading and delivery of goods from any city and port in the world.`,
    ua: `Ми пропонуємо контейнерні, збірні, насипні та негабаритні морські перевезення. Завдяки розгалуженій агентській мережі ми організовуємо завантаження та доставку вантажів з будь-якого міста та порту світу.`,
  },
}

export const main = {
  title: {
    pl: 'Transport drogą morską.<br/>Wybierz jedną spośród wielu usług transportu morskiego',
    en: 'Transport by sea. <br/>Choose from a wide range of sea transport services',
    ua: 'Транспорт морським транспортом. <br/>Виберіть одну із різноманітних послуг морського транспорту',
  },
  body: {
    pl: `
    <p>
    Od wieków przewożenie towarów drogą morską stanowiło podstawę wymiany handlowej, rozwoju i bogacenia się narodów.
    Obecnie <strong>ponad 80%</strong> wolumenu handlu światowego jest przewożone statkami.
    Omida Sea And Air ma w tym swój udział organizując morski transport kontenerowy <strong>(FCL)</strong>, niepełnokontenerowy <strong>(LCL)</strong>, drobnicowy typu <strong>Break Bulk</strong>, masowy oraz ładunków płynnych i ponadgabarytowych <strong>(Project Cargo)</strong>.
    </p>`,
    en: `
    <p>
    For centuries, the transport of goods by sea has been the basis of trade, development and enrichment of nations. Currently, <strong>more than 80%</strong> of the global trade volume is transported by ships. Omida Sea And Air contributes to this by organizing sea full container transport <strong>(FCL)</strong>, less than container <strong>(LCL)</strong>, <strong>Break Bulk</strong>, liquid and oversized cargo transport <strong>(Project Cargo)</strong>.
    </p>
    `,
    ua: `
    <p>
    Століттями перевезення вантажів морем було основою торгівлі, розвитку та збагачення народів. В даний час більше 80% світового товарообігу транспортується морським транспортом. Omida Sea And Air сприяє цьому, організовуючи морське перевезення контейнерів (FCL), не повних контейнерів (LCL), Break Bulk , наливних і негабаритних вантажів (Project Cargo).
    </p>
    `,
  },
}
