import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/transport-morski'
import { useLangContext } from '../../context/lang.context'
import { useFormContext } from '../../context/form.context'
import Intro from '../../components/Intro'
import Button from '../../components/Button'
import { sLinkTiles } from '../../style'
import LinkTile from '../../components/LinkTile'
import Main from '../../components/Main'
import Video from '../../components/Video'

const TransportMorski = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image, services } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/transport-morski.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      services: allContentfulService(sort: { fields: order }) {
        nodes {
          title
          type {
            name
          }
          lang {
            slug
          }
          slug
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
          }
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn more',
      ua: 'ДІЗНАТИСЯ БІЛЬШЕ ',
    },
    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Wycena transportu',
      en: 'Transport Quote',
      ua: 'ЦІНА ТРАНСПОРТУВАННЯ',
    },
    action: () => setFormOpen(true),
  }

  const buttonBack = {
    text: {
      pl: 'Powrót do transportu',
      en: 'Back to transport',
      ua: 'ПОВЕРНЕННЯ ДО ТРАНСПОРТУ',
    },
    link: '/transport/',
  }

  const links = services.nodes
    .filter((node) => node.lang.slug === lang)
    .filter((node) => node.type.name === 'sea')
    .map(({ slug, title, image }) => ({
      slug: `/transport/morski/${slug}/`,
      title,
      image,
      subtitle: {
        pl: 'Transport Morski',
        en: 'Sea Transport',
        ua: 'Морський транспорт',
      },
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main h={1} title={main.title[lang]} article={main.body[lang]}>
        <div css={{ paddingTop: '4rem' }} />
        <Video video={'https://www.youtube.com/embed/SCWTeb-Ipl4'} />
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
        <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button>
      </Main>
    </Layout>
  )
}

export default TransportMorski
